<template>
    <div>
        <h3>Diese Seite wurde nicht gefunden</h3>
        <b-button class="float-right" style="color: white" to="/">Zurück zur Startseite</b-button>
    </div>
</template>

<script>
    //@group Views
    //@vuese
    //404
    export default {
        name: "notFound"
    }
</script>

<style scoped>

</style>
